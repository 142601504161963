import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, } from 'react-redux';
import rootReducer from './rootReducer';
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: true,
    }),
});
const resetState = () => ({
    type: 'RESET_STATE',
});
// Add the following function to refresh the state
const refreshState = () => {
    store.dispatch(resetState());
};
const { dispatch } = store;
const useSelector = useAppSelector;
const useDispatch = () => useAppDispatch();
export { dispatch, refreshState, store, useDispatch, useSelector };
